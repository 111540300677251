import * as React from 'react';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import AppTextInput from '../../../components/forms/AppTextInput';
import AppFormDropdown from '../../../components/forms/AppFormDropdown';
import FormButton from '../../../components/forms/AppFormButton';
import {
  useAddNewTenantMutation,
  useEditTenantMutation,
  useGetTenantOptionsQuery,
  useGetTenantLogoQuery,
  useUploadLogoMutation,
  useStartUsingStorageAccountMutation
} from '../../api/api';
import { Message, RtkErrorMessage } from '../../../../utility/notifications/Message';
import { Spinner } from 'react-bootstrap';
import AppImageUpload from '../../../components/forms/AppImageUpload';
import * as Permission from '../../../../constants/permissions';
import AppNotification from '../../../../utility/notifications/AppNotifications';
import BiSpecificSettings from '../../components/BiSpecificSettings';
import { type CreateTenantRequestDto, type TenantDto, type UpdateTenantRequestDto } from '../../../../api/data-contracts';
import TenantSetStatusButton from '../../components/TenantSetStatusButton';
import IsRouteOrActionAllowed from '../../../../utility/IsRouteOrActionAllowed';
import { useListView } from '../../TenantListView';
import DatabaseSettings from '../../components/DatabaseSettings';
import AppFormDropdownAnt from '../../../components/forms/AppFormDropdownAnt';
import AppCheckInput from '../../../components/forms/AppCheckInput';

interface Props {
  tenantForEdit: TenantDto;
  editMode: boolean;
}

const editTenantSchema = Yup.object().shape({
  projectName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(255, 'Maximum 255 symbols')
    .required('Project name is required'),

  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(255, 'Maximum 255 symbols')
    .required('Company name is required'),

  tenantLogo: Yup.string().required().label('Logo'),

  databaseName: Yup.string()
    .when('editionId', {
      is: (edition: number) => edition === 1,
      then: Yup.string().nullable().required('Database name is required'),
    })
    .when('editionId', {
      is: (edition: number) => edition !== 1,
      then: Yup.string().nullable(),
    }),

  databaseServer: Yup.string()
    .when('editionId', {
      is: (edition: number) => edition === 1,
      then: Yup.string().nullable().required('Database name is required'),
    })
    .when('editionId', {
      is: (edition: number) => edition !== 1,
      then: Yup.string().nullable(),
    }),

  isMappingEnabled: Yup.boolean().required('Mapping is required!'),
});

function EditForm({ tenantForEdit, editMode, }: Props) {
  const [globalIsLoading, setGlobalIsLoading] = useState(false);
  const [addNewTenant, { isLoading: AddLoading, }] = useAddNewTenantMutation();
  const [editTenant, { isLoading: EditLoading, }] = useEditTenantMutation();
  const [uploadLogoTenant] = useUploadLogoMutation();

  const { data: tenantOptions, isLoading: tenantOptionLoading, error: tenantOptionError, } = useGetTenantOptionsQuery(tenantForEdit.id);

  const [startUsingStorageAccountMution, { isLoading: storageAccountIsLoading }] = useStartUsingStorageAccountMutation();

  const { data: tenantLogo, } = useGetTenantLogoQuery(tenantForEdit.id, {
    skip: !tenantForEdit.id,
  });

  useEffect(() => {
    if (tenantLogo) {
      setPreview(`data:image/jpg;base64, ${tenantLogo}`);
    }
  }, [tenantLogo]);

  useEffect(() => {
    if (AddLoading || EditLoading) {
      setGlobalIsLoading(true);
    } else {
      setGlobalIsLoading(false);
    }
  }, [AddLoading, EditLoading]);

  const { toggle, } = useListView();

  const [fileSelected, setFileSelected] = React.useState<File>();
  const [preview, setPreview] = useState('');

  useEffect(() => {
    if (!fileSelected) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(fileSelected);
    reader.onload = function () {
      setPreview(reader.result as string);
    };
  }, [fileSelected]);

  const handleSubmit = async (data: any) => {
    if (editMode) {
      await editTenant(data as UpdateTenantRequestDto)
        .unwrap()
        .then(() => {
          if (data.id) {
            uploadFile(data.id);
            toggle(null);
            AppNotification.success('Company management', `Company ${data.name} is successfully updated`);
          }
        })
        .catch(async (error) => { await showError('Editing company failed', error); });
    } else {
      await addNewTenant(data as CreateTenantRequestDto)
        .unwrap()
        .then((result) => {
          uploadFile(result);
          toggle(null);
          AppNotification.success('Company management', `Company ${data.name} is successfully created`);
        })
        .catch(async (error) => { await showError('Creating company failed', error); });
    }
  };

  const handleImageChange = function (file: File) {
    setFileSelected(file);
  };

  const uploadFile = function (id: any) {
    if (!fileSelected) {
      return;
    }

    const formData = new FormData();
    formData.append('TenantId', id.toString() as string);
    formData.append('TenantLogo', fileSelected);

    uploadLogoTenant(formData)
      .unwrap()
      .catch(async (error) => {
        await showError('Uploading failed logo', error);
        toggle(null);
      });
  };

  const showError = async (title: string, error: any) => {
    await RtkErrorMessage(title, error);
  };

  if (tenantOptionLoading) {
    return (
      <div className="card-body d-flex justify-content-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  } if (tenantOptionError) {
    return (
      <div className="card-body d-flex justify-content-center">
        <p>Fetching company options went wrong</p>
      </div>
    );
  }

  const startUsingStorageAccount = async () => {
    const result = await Message('Confirm', "Are you sure you want to migrate to a storage account?", 'warning');

    if (!tenantForEdit.id || !result.isConfirmed) {
      return;
    }

    await startUsingStorageAccountMution({ tenantId: tenantForEdit.id })
      .unwrap()
      .then(() => {
      })
      .catch(async (error) => { await showError('Migrating to storage account failed', error); });
  };

  return (
    <Formik
      initialValues={tenantForEdit}
      validationSchema={editTenantSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, values, isSubmitting, }) => (
        <form className="form w-100 h-100" noValidate>
          <div className="d-flex flex-column justify-content-between h-100">
            <AppImageUpload
              required
              sizeInBytes={500000}
              imagePreview={preview}
              name="tenantLogo"
              label="Company Logo"
              handleImageChange={handleImageChange}
            />

            <div className="fv-row mb-5">
              <AppTextInput
                type="text"
                name="projectName"
                label="Project name (for internal use)"
                required
              />
            </div>

            <div className="fv-row mb-5">
              <AppTextInput
                type="text"
                name="name"
                label="Company name (what the user sees)"
                required
              />
            </div>

            <div className="fv-row mb-5">
              <AppFormDropdownAnt
                name="editionId"
                loading={tenantOptionLoading}
                options={tenantOptions?.editions ?? []}
                label="Editions"
              />
            </div>

            <DatabaseSettings databaseList={tenantOptions?.databaseServers ?? []} />

            {/* Storage account checks can be disabled when all tenants are migrated */}
            <div className="fv-row my-3">
              <h4 className='mt-4'>Forecasting settings</h4>

              <AppCheckInput name="isForecastingEnabled" label="Enable forecasting for company" />
            </div>

            <BiSpecificSettings values={values} />

            {tenantForEdit.powerBiWorkspaceUrl && (
              <a href={tenantForEdit.powerBiWorkspaceUrl}>See PowerBI workspace</a>
            )}

            {/* Storage account checks can be disabled when all tenants are migrated */}
            {editMode && (tenantForEdit.isUsingStorageAccount ? (
              null
            )
              : (
                <button type='button' className="btn btn-outline btn-outline-dashed btn-outline-default"
                  disabled={storageAccountIsLoading}
                  onClick={(e) => {
                    e.stopPropagation();
                    startUsingStorageAccount();
                  }}>

                  {storageAccountIsLoading ? (
                    <Spinner animation="border" role="status" size="sm">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  )
                    : "Migrate to storage account"}
                </button>
              )
            )}
          </div>

          <div className="text-end pt-5 d-flex flex-row-reverse justify-content-between">
            {
              ((editMode && IsRouteOrActionAllowed.IsRouteOrActionAllowed(Permission.Host_Tenants_Edit)) ||
                (!editMode && IsRouteOrActionAllowed.IsRouteOrActionAllowed(Permission.Host_Tenants_Create))) &&
              (
                <div>
                  <button
                    type="reset"
                    className="btn btn-light me-3"
                    data-kt-users-modal-action="cancel"
                    onClick={() => { toggle(null); }}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </button>

                  <FormButton
                    width="w-20"
                    type="button"
                    text="Submit"
                    state={globalIsLoading}
                    color="primary"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  />
                </div>
              )
            }

            {editMode && IsRouteOrActionAllowed.IsRouteOrActionAllowed(Permission.Host_Tenants_Delete) && (
              <TenantSetStatusButton tenantName={tenantForEdit.name ?? ''} isActive={tenantForEdit.isActive ?? false} />
            )}
          </div>
        </form>
      )
      }
    </Formik >
  );
};

export { EditForm };
