// ** Auth Endpoints
export default {
  loginMasterUserEndpoint: '/auth/AuthenticateMasterUser',
  loginUserEndpoint: '/Auth/AuthenticateTenant',
  tenantForMasterUserMasterUserEndpoint: '/auth/GetTenantsForMasterUser',
  mfaOptionsMasterUserEndpoint: '/auth/GetMfaOptions/',
  sendTwoAuthFactorMasterUserEndpoint: '/auth/SendTwoFactorAuthCode/',

  favouriteMasterUserEndpoint: '/v1/MasterUsers/FavoriteTenant',
  resetPasswordMasterUserEndpoint: '/v1/MasterUsers/ResetPassword/',
  changePasswordMasterUserEndpoint: '/v1/MasterUsers/ChangePassword/',

  GetGoogleAuthenticatorSettingsEnabledEndpoint: '/v1/Common/UserProfile/GoogleAuthenticatorIsEnabled',
  GoogleAuthenticatorSettingsEndpoint: '/v1/Common/UserProfile/GoogleAuthenticatorSettings',

  SessionCommonEndpoint: '/v1/Common/Sessions',

  // Impersonation
  loginImpersonateHostEndpoint: '/auth/Impersonate',
  loginImpersonatedImpersonatedAuthenticateHostEndpoint: '/auth/ImpersonatedAuthenticate',

  // File Download
  downloadFileEndpoint: '/v1/tenant/DocumentCabinet/File/download/',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageMasterTokenKeyName: 'accessTokenMaster',
  storageTokenKeyName: 'accessToken',
  storageSignalRTokenKeyName: 'signalRToken',
  storagePermissionsKeyName: 'permissions',
  storageUserDateKeyName: 'userData',
  storageTenantDataKeyName: 'sessionTenantData',
  storageImpersonateDataKeyName: 'impersonateData',
  storageTwoFactorRememberToken: 'twoFactorRememberClientToken',
};
