/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import FormButton from '../../components/forms/AppFormButton';
import AppTextInput from '../../components/forms/AppTextInput';
import AppCheckInput from '../../components/forms/AppCheckInput';
import SelectTenantModal from '../../modals/SelectTenantModal';
import useJwt from '../../../auth/jwt/useJwt';
import { type MasterUserTenant } from '../../../types';
import { Message } from '../../../utility/notifications/Message';
import { TwoStepAuth } from './TwoStepAuth';
import { getTwoFactorRememberClientToken } from '../../../utility/Utils';
import routeNames from '../../../routing/routeNames';

const loginSchema = Yup.object().shape({
  userNameOrEmailAddress: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string().required('Password is required'),
  rememberClient: Yup.bool(),
});

const initialValues = {
  userNameOrEmailAddress: '',
  password: '',
  rememberClient: true,
  twoFactorRememberClientToken: '',
  twoFactorVerificationCode: '',
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showSelectTenantModal, setShowSelectTenantModal] = useState<boolean>(false);
  const togglePasswordVisible = () => { setPasswordVisible((value) => !value); };
  const [availableTenants, setAvailableTenants] = useState<[MasterUserTenant]>();
  const [showHostEnvLink, setshowHostEnvLink] = useState<boolean>(false);
  const navigate = useNavigate();
  // TODO: combine this in one object
  const [showTwoStepAuth, setShowTwoStepAuth] = useState<number>();
  const [authProvider, setAuthProvider] = useState<string[]>([]);
  const { jwt, } = useJwt();

  initialValues.twoFactorRememberClientToken = getTwoFactorRememberClientToken() || '';


  const setSessionInfo = async () => {
    await jwt.GetSessionInfo()
      .then((sessionInfo) => {
        if (sessionInfo.status === 200) {
          console.log(sessionInfo.data);
          jwt.setSession(sessionInfo.data);

          navigate(routeNames.tenant);
        }
      }).catch((err) => {
        if (err.response) {
          Message('Impersonation failed!', err.response.data.exception, 'error', {});
        }
      });
  }

  const handleSubmit = async (data: any) => {
    setLoading(true);
    jwt
      .loginMaster({
        ...data,
        twoFactorVerificationCode: data.twoFactorVerificationCode.replace(/ /g, ''),
      })
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          if (res.data.requiresTwoFactorVerification) {
            setShowTwoStepAuth(res.data.userId);
            setAuthProvider(res.data.twoFactorAuthProviders);
            return;
          }

          const data = {
            accessToken: res.data.data.accessToken,
            expireInSeconds: res.data.data.expireInSeconds,
            tenants: res.data.data.tenants,
            twoFactorToken: res.data.data.twoFactorRememberClientToken,
          };

          jwt.handleMasterLogin(data);

          if (
            res.data.data.tenants.data.tenants.length > 1 ||
            (res.data.data.tenants.data.tenants.length === 1 &&
              res.data.data.tenants.data.hasAccessToHostEnvironment)
          ) {
            setAvailableTenants(res.data.data.tenants.data.tenants);
            setshowHostEnvLink(res.data.data.tenants.data.hasAccessToHostEnvironment);
            setShowSelectTenantModal(true);
            setLoading(false);
          } else if (res.data.data.tenants.data.hasAccessToHostEnvironment) {
            jwt.login().then((resData) => {
              if (resData.status === 200) {
                jwt.setPermissions(resData.data.data.userData.grantedPermissions);
                delete resData.data.data.userData.grantedPermissions;

                const result = {
                  accessToken: resData.data.data.accessToken,
                  encryptedAccessToken: resData.data.data.encryptedAccessToken,
                  userData: resData.data.data.userData,
                };

                jwt.handleLogin(result);
                navigate(routeNames.tenant);
              }
            });
          } else {
            jwt
              .login(
                res.data.data.tenants.data.tenants[0]
                  ? res.data.data.tenants.data.tenants[0].id
                  : null
              )
              .then(async (resData) => {
                if (resData.status === 200) {
                  jwt.setPermissions(resData.data.data.userData.grantedPermissions);
                  delete resData.data.data.userData.grantedPermissions;

                  const result = {
                    accessToken: resData.data.data.accessToken,
                    encryptedAccessToken: resData.data.data.encryptedAccessToken,
                    userData: resData.data.data.userData,
                  };

                  jwt.handleLogin(result);

                  await setSessionInfo();
                  navigate(routeNames.fallback);
                }
              })
              .catch(err => {
                if (err.response) {
                  setLoading(false);
                  Message('Log in failed!', err.response.data.exception, 'error', {});
                }
              });
          }
        }
      })
      .catch((err) => {
        if (err.response) {
          setLoading(false);

          Message('Log in failed!', err.response.data.exception, 'error', {});
        }
      });
  };

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={loginSchema} onSubmit={handleSubmit}>
        {({ handleSubmit, isSubmitting, isValid, }) => (
          <form className="form w-100 h-100" onSubmit={handleSubmit} noValidate>
            {showTwoStepAuth
              ? (
                <TwoStepAuth
                  loading={loading}
                  onFactorSubmit={handleSubmit}
                  userId={showTwoStepAuth}
                  providerList={authProvider}
                />
              )
              : (
                <div className="d-flex flex-column justify-content-between h-100">
                  <div>
                    <div className="text-center">
                      <h1 className="mt-5 mb-10">Sign In</h1>
                    </div>

                    <div className="fv-row mb-8">
                      <AppTextInput
                        autoFocus
                        type="email"
                        name="userNameOrEmailAddress"
                        placeholder="Email"
                        label="Email address"
                      />
                    </div>

                    <div className="fv-row mb-5">
                      <AppTextInput
                        type={passwordVisible ? 'text' : 'password'}
                        name="password"
                        placeholder="Password"
                        label="Password"
                        appendIcon={passwordVisible ? 'fa fa-eye' : 'fa fa-eye-slash'}
                        onClick={togglePasswordVisible}
                      />
                    </div>

                    <div className="fv-row mb-5">
                      <AppCheckInput name="rememberClient" label="Remember me" size="form-check-sm" />
                    </div>
                  </div>

                  <div className="text-center">
                    <FormButton
                      type="submit"
                      text="Log in"
                      state={loading}
                      color="primary"
                      disabled={isSubmitting || !isValid}
                      onClick={() => handleSubmit}
                    />

                    <div className="text-center text-muted text-uppercase fw-bolder m-3">or</div>

                    <Link
                      to="/auth/forgot-password"
                      className="link-primary fs-6 fw-bolder"
                      style={{ marginLeft: '5px', }}
                    >
                      Forgot Password ?
                    </Link>
                  </div>
                </div>
              )}
          </form>
        )}
      </Formik>

      {availableTenants && (
        <SelectTenantModal
          show={showSelectTenantModal}
          tenants={availableTenants}
          handleClose={() => { setShowSelectTenantModal(false); }}
          showHostEnvLink={showHostEnvLink}
        />
      )}
    </>
  );
}
