export const DataTypesMap = new Map<number, string>([
  [1, 'String'],
  [2, 'Date'],
  [3, 'Integer']
]);

export interface PowerBiReportDto {
  tenantId: number;
  displayName: string;
  viewGroupId: number;
  parameters: PowerBiReportParameterDto[];
  isFilterVisible: boolean;
  isPageNavigationVisible: boolean;
  originalReportName: string;
  isEditable: boolean;
};

export interface PowerBIReportRoot {
  reports: Array<{
    name: string;
    apiReportId: number;
  }>;
}

export interface PowerBIReportDropdownDto {
  name: string;
  id: number;
  label: string;
  value: string;
}

export interface PowerBiReportParameterDto {
  id?: number;
  sortId?: number;
  name: string;
  dataType: number | string;
  powerBiReportId?: number;
  values?: PowerBiReportParameterValueDto[];
}

export interface PowerBiReportParameterValueDto {
  id?: number;
  PowerBiReportParameterId?: number;
  value: string;
}

export interface CreateMigrateReportDto {
  tenantId: number;
  powerBiUserName: string;
  powerBiPassword: string;
}

export interface AcceptedReports {
  reportId: string;
  displayName: string;
}

export interface RejectedReports {
  reportId: string;
  displayName: string;
}

export interface GroupedPowerBiReports {
  acceptedPowerbiReports?: string;
  rejectedPowerbiReports?: string;
  status?: boolean;
}
