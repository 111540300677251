import { type QueryState } from '../views/tenant/TenantListView';

export interface Nullable<T> {
  T: T | null;
}

export interface UserPermission {
  permissions: string[];
}

export type buttonColors = 'primary' | 'secondary' | 'info' | 'warning' | 'danger' | 'dark' | 'light' | 'success';

export interface PaginationState {
  skipCount: number;
  maxResultCount: ItemsPerPage;
  page: number;
  totalItemCount: number;
  pageCount: number;
}

export interface SortState {
  sorting?: string;
  order?: 'asc' | 'desc';
}

export interface SearchState {
  keyword?: string;
}

export interface QueryRequestContextProps {
  state: QueryState;
  updateState: (updates: Partial<QueryState>) => void;
}

export const itemsPerPage = [10, 30, 50, 100] as const;
export type ItemsPerPage = typeof itemsPerPage[number];

export interface GetRequestFilter {
  currentPage: number;
  totalItemCount: number;
  itemsPerPage: ItemsPerPage;
  maxResultCount?: ItemsPerPage;
  totalAmountOfPages: number;
  skipCount: number;
  keyWord: string;
  sorting: string;
}

export type GetRequestFilterTenant = {
  tenantId: number | null;
} & GetRequestFilter;

export type CallBackInterface = (message: string) => void;

export interface LoginOutput {
  accessToken: string;
  encryptedAccessToken: string;
  userData: UserData;
}

export interface ImpersonateOutput {
  accessToken: string;
  impersonateData: ImpersonateData | {};
  userData: UserData;
}

export interface UserData {
  firstName: string;
  surName: string;
  email: string;
  isHost: boolean;
  tenants: number[];
  hasAccessToHostEnvironment: boolean;
  userId: number;
}

export interface ImpersonateData {
  emailAdress: string;
  tenantId: number;
  roles: string[];
}

export interface MasterLoginDataOutput {
  hasAccessToHostEnvironment: boolean;
  tenants: MasterLoginTenantOutput[];
}

export interface MasterLoginTenantOutput {
  id: number;
  name: string;
  logoUrl?: any;
  isFavorite: boolean;
}

export interface MasterUserTenant {
  id: number;
  name: string;
  tenantLogo?: any;
  isFavorite: boolean;
}

export interface MasterLoginTenantsOutput {
  data: MasterLoginDataOutput;
}

export interface MasterLoginOutput {
  accessToken: string;
  expireInSeconds: number;
  twoFactorToken: string;
  tenants: MasterLoginTenantsOutput;
}

export interface LoginInput {
  userNameOrEmailAddress: string;
  password: string;
  rememberClient: boolean;
}

export interface JwtConfig {
  loginMasterUserEndpoint: string;
  loginUserEndpoint: string;
  tokenType: string;
  storageMasterTokenKeyName: string;
  storageTokenKeyName: string;
  storageUserDateKeyName: string;
  storageImpersonateDataKeyName: string;
  storagePermissionsKeyName: string;
  storageTwoFactorRememberToken: string;
  storageSignalRTokenKeyName: string;
  storageTenantDataKeyName: string;
}

export interface ResetPasswordFormInput {
  email: string;
}
